import {Component, OnInit} from '@angular/core';
import { ThemeService } from '@frontend/services';
import {environment} from "@frontend/envs/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isRemaxTheme$ = this._themeService.isRemaxTheme$;
  isPropupTheme$ = this._themeService.isPropupTheme$;
  isSulekTheme$ = this._themeService.isSulkeTheme$;
  isWohnCloudTheme$ = this._themeService.isWohnCloudTheme$;
  isFroeschlTheme$ = this._themeService.isFroeschlTheme$;
  isGraslTheme$ = this._themeService.isGraslTheme$;
  isImmo4Theme$ = this._themeService.isImmo4Theme$;
  isImmobilienMelkTheme$ = this._themeService.isImmobilienMelkTheme$;
  isAlteraTheme$ = this._themeService.isAlteraTheme$;
  isFinovaTheme$ = this._themeService.isFinovaTheme$;
  isHagsteinerTheme$ = this._themeService.isHagsteinerTheme$;
  isQualisTheme$ = this._themeService.isQualisTheme$;
  isEdelweissTheme$ = this._themeService.isEdelweissTheme$;
  isRemaxCollectionTheme$ = this._themeService.isRemaxCollectionTheme$;
  isRemaxClassicTheme$ = this._themeService.isRemaxClassicTheme$;
  isProschTheme$ = this._themeService.isProschTheme$;
  isAhImmobilienTheme$ = this._themeService.isAhImmobilienTheme$;
  isAaaaImmobilienTheme$ = this._themeService.isAaaaImmobilienTheme$;
  isNoriskTheme$ = this._themeService.isNoriskTheme$;
  isMayrImmoTheme$= this._themeService.isMayrImmoTheme$;
  isEraImmoTheme$= this._themeService.isEraImmoTheme$;
  isSonnbergerTheme$ = this._themeService.isSonnbergerTheme$;
  isVirimoTheme$ = this._themeService.isVirimoTheme$;
  isRealwertTheme$ = this._themeService.isRealwertTheme$;
  isStriglTheme$ = this._themeService.isStriglTheme$;
  isImmobilienringTheme$ = this._themeService.isImmobilienringTheme$;

  ngOnInit() {
    this.loadGoogleMapsScript().then(() => {
      console.log('Google Maps API is ready.');
    }).catch((error) => {
      console.error('Failed to load Google Maps API:', error);
    });
  }

  private loadGoogleMapsScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const existingScript = document.getElementById('googleMapsScript');
      if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'googleMapsScript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          console.log('Google Maps API loaded successfully.');
          resolve();
        };

        script.onerror = (error) => {
          console.error('Error loading Google Maps API:', error);
          reject(error);
        };

        document.head.appendChild(script);
      } else {
        resolve();  // Script already loaded
      }
    });
  }

  constructor(private readonly _themeService: ThemeService) { }
}
