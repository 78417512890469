import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateExclusiveAgreementDocument {
  generateExclusiveAgreementDocument: { storageUrl: string };
}

export const GENERATE_EXCLUSIVE_AGREEMENT_DOCUMENT = gql`
  mutation GenerateExclusiveAgreementDocument($accessId: String!, $exclusiveAgreementId: String!) {
    generateExclusiveAgreementDocument(accessId: $accessId, exclusiveAgreementId: $exclusiveAgreementId) {
      storageUrl
    }
  }
`;