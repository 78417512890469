import { gql } from 'apollo-angular';

export interface GraphQLResponseSendExclusiveAgreementForAgentReview {
  sendExclusiveAgreementForAgentReview: string;
}

export const SEND_EXCLUSIVE_AGREEMENT_FOR_AGENT_REVIEW = gql`
  mutation SendExclusiveAgreementForReview($exclusiveAgreementId: String!) {
    sendExclusiveAgreementForAgentReview(exclusiveAgreementId: $exclusiveAgreementId)
  }
`;