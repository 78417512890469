import { gql } from 'apollo-angular';

export interface GraphQLResponseUpdateExclusiveAgreement {
  updateExclusiveAgreement: string;
}

export const UPDATE_EXCLUSIVE_AGREEMENT = gql`
    mutation UpdateExclusiveAgreement($accessId: String!, $data: ExclusiveAgreementUpdateInput!) {
        updateExclusiveAgreement(accessId: $accessId, data: $data)
    }
`;