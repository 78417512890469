/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import {
  SEND_BUYING_OFFER,
  SEND_RENTAL_OFFER,
  DELETE_UPLOADED_DOCUMENT,
  GraphQLResponseSendBuyingOffer,
  GraphQLResponseDeleteUploadedDocument,
  GraphQLResponseSendRentalOffer,
  GraphQLResponseCreateCustomerPortal,
  CREATE_CUSTOMER_PORTAL,
  UPDATE_PROPERTY_OVERVIEW,
  GraphQLResponseUpdatePropertyOverview,
  GraphQLResponseCreateOverviewFeedback,
  CREATE_OVERVIEW_FEEDBACK,
  GENERATE_BUYING_OFFER_DOCUMENT,
  GraphQLResponseGenerateBuyingOfferDocument,
  GraphQLResponseGenerateRentalOfferDocument,
  GENERATE_RENTAL_OFFER_DOCUMENT,
  GraphQLResponseSignOwnerRentalOfferDocument,
  SIGN_OWNER_RENTAL_OFFER_DOCUMENT,
  GENERATE_OWNER_BUYING_OFFER_DOCUMENT,
  GraphQLResponseGenerateOwnerBuyingOfferDocument,
  GraphQLResponseSignOwnerBuyingOfferDocument,
  SIGN_OWNER_BUYING_OFFER_DOCUMENT,
  SEND_BUYING_COUNTER_OFFER_DOCUMENT,
  GraphQLResponseSendBuyingCounterOfferDocument,
  GraphQLResponseUpdateBuyingOffer,
  UPDATE_BUYING_OFFER,
  GraphQLResponseUpdateOwnerPortalLanguage,
  UPDATE_OWNER_PORTAL_LANGUAGE,
  DELETE_EXCLUSIVE_AGREEMENT_FILE,
  GraphQLResponseDeleteExclusiveAgreementFile,
  SEND_EXCLUSIVE_AGREEMENT_FOR_AGENT_REVIEW,
  GraphQLResponseSendExclusiveAgreementForAgentReview,
  GraphQlResponseSaveOwnerDocuments,
  SAVE_OWNER_DOCUMENTS,
  GraphQLResponseGenerateSelfDeclarationDocument,
  GENERATE_SELF_DECLARATION_DOCUMENT,
  VERIFY_CUSTOMER_PORTAL_PASSWORD, GraphQlResponseVerifyCustomerPortalPassword,
} from '@frontend/graphql/mutation';
import {
  GET_PROPERTY_OVERVIEW_BY_ID,
  GET_PROPERTY_OVERVIEW_DOCUMENTS,
  GET_RENTAL_OFFER_DOCUMENT_PREVIEW,
  GET_BUYING_OFFER_DOCUMENT_PREVIEW,
  GraphQLResponsePropertyOverviewById,
  GraphQLResponsePropertyOverviewDocuments,
  GraphQLResponseRentalOfferDocumentPreview,
  GraphQLResponseBuyingOfferDocumentPreview,
  GraphQLResponseAgentSettings,
  GET_AGENT_SETTINGS,
  GraphQLResponseOverviewFeedback,
  GET_OVERVIEW_FEEDBACK,
  GET_OWNER_OVERVIEW,
  GraphQLResponseOwnerOverview,
  GET_RENTAL_OFFER_PREVIEW_DATA,
  GraphQLResponseRentalOfferPreviewData,
  GET_BUYING_OFFER_PREVIEW_DATA,
  GraphQLResponseBuyingOfferPreviewData,
  GraphQLResponseOwnerExclusiveAgreementPreviewData,
  GET_OWNER_EXCLUSIVE_AGREEMENT_PREVIEW_DATA,
  GraphQLResponsePropertyOverviews,
  GET_PROPERTY_OVERVIEWS,
  GraphQLResponseSelfDeclarationDocumentPreview, GET_SELF_DECLARATION_DOCUMENT_PREVIEW,
} from '@frontend/graphql/query';
import {
  PropertyOverview,
  PropertyOverviewDocuments,
  RentalOfferPreviewDocument,
  BuyingOfferPreviewDocument,
  PropupDocumentType,
  PropertyOverviewAgent,
  PropertyOverviewUpdate,
  OverviewFeedback,
  CreateOverviewFeedback,
  OwnerOverview,
  RentalOfferPreviewData,
  BuyingOfferPreviewData,
  SellersAcceptance,
  BuyersAcceptanceWithChange, ViewingPassPreviewData, ViewingPassPreviewDocument,
  BuyingOfferUpdate,
  OwnerExclusiveAgreementPreviewData,
  UploadedImage, SelfDeclarationPreviewDocument, ExclusiveAgreementUpdate,
} from '@frontend/models';
import { Apollo } from 'apollo-angular';
import { GENERATE_OWNER_RENTAL_OFFER_DOCUMENT, GraphQLResponseGenerateOwnerRentalOfferDocument } from 'packages/frontend/src/app/graphql/schema/mutation/generate-owner-rental-offer-document';
import {Observable, map} from 'rxjs';
import {
  GET_PROPERTY_OVERVIEW_BY_OBJECTID,
  GraphQLResponsePublicPropertyOverview
} from "../graphql/schema/query/public-property-overview";
import {
  GET_VIEWING_PASS_PREVIEW_DATA,
  GraphQLResponseViewingPassPreviewData
} from "../graphql/schema/query/viewing-pass-preview-data";
import {
  GET_VIEWING_PASS_DOCUMENT_PREVIEW,
  GraphQLResponseViewingPassDocumentPreview
} from "../graphql/schema/query/viewing-pass-document-preview";
import { GraphQLResponseSendViewingPass, SEND_VIEWING_PASS } from "../graphql/schema/mutation/send-viewing-pass";
import {
  GENERATE_VIEWING_PASS_DOCUMENT,
  GraphQLResponseGenerateViewingPassDocument
} from "../graphql/schema/mutation/generate-viewing-pass-document";
import {
  GraphQLResponseUpdateCustomerLanguage,
  UPDATE_CUSTOMER_LANGUAGE
} from "../graphql/schema/mutation/update-customer-language";
import {
  GraphQLResponseSendSelfDeclaration,
  SEND_SELF_DECLARATION
} from "../graphql/schema/mutation/send-self-declaration";
import {
  GraphQLResponseUpdateExclusiveAgreement,
  UPDATE_EXCLUSIVE_AGREEMENT
} from "../graphql/schema/mutation/update-exclusive-agreement";
import {
  GENERATE_EXCLUSIVE_AGREEMENT_DOCUMENT,
  GraphQLResponseGenerateExclusiveAgreementDocument
} from "../graphql/schema/mutation/generate-exclusive-agreement-document";

@Injectable({ providedIn: 'root' })
export class PropertyService {
  constructor(private readonly _apollo: Apollo) { }

  getOwnerOverview$(ownerPortalId: string): Observable<OwnerOverview> {
    return this._apollo.query<
      GraphQLResponseOwnerOverview,
      { ownerPortalId: string; }
    >({
      query: GET_OWNER_OVERVIEW,
      variables: { ownerPortalId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.ownerOverview));
  }

  getOwnerExclusiveAgreementPreviewData$(exclusiveAgreementId: string): Observable<OwnerExclusiveAgreementPreviewData> {
    return this._apollo.query<
      GraphQLResponseOwnerExclusiveAgreementPreviewData,
      { exclusiveAgreementId: string; }
    >({
      query: GET_OWNER_EXCLUSIVE_AGREEMENT_PREVIEW_DATA,
      variables: { exclusiveAgreementId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.exclusiveAgreementPreviewData));
  }

  generateOwnerRentalOfferDocument$(rentalOfferId: string): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseGenerateOwnerRentalOfferDocument,
      { rentalOfferId: string }
    >({
      mutation: GENERATE_OWNER_RENTAL_OFFER_DOCUMENT,
      variables: { rentalOfferId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data!.generateOwnerRentalOfferDocument.storageUrl));
  }

  sendExclusiveAgreementForAgentReview$(exclusiveAgreementId: string): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseSendExclusiveAgreementForAgentReview,
      { exclusiveAgreementId: string }
    >({
      mutation: SEND_EXCLUSIVE_AGREEMENT_FOR_AGENT_REVIEW,
      variables: { exclusiveAgreementId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data!.sendExclusiveAgreementForAgentReview));
  }

  generateOwnerBuyingOfferDocument$(buyingOfferId: string): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseGenerateOwnerBuyingOfferDocument,
      { buyingOfferId: string }
    >({
      mutation: GENERATE_OWNER_BUYING_OFFER_DOCUMENT,
      variables: { buyingOfferId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data!.generateOwnerBuyingOfferDocument.storageUrl));
  }

  signOwnerRentalOfferDocument$(rentalOfferId: string, landlordAcceptance: string): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseSignOwnerRentalOfferDocument,
      { rentalOfferId: string; landlordAcceptance: string }
    >({
      mutation: SIGN_OWNER_RENTAL_OFFER_DOCUMENT,
      variables: { rentalOfferId, landlordAcceptance },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data!.signOwnerRentalOfferDocument.storageUrl));
  }

  signOwnerBuyingOfferDocument$(buyingOfferId: string, sellersAcceptance: SellersAcceptance): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseSignOwnerBuyingOfferDocument,
      { buyingOfferId: string; sellersAcceptance: SellersAcceptance }
    >({
      mutation: SIGN_OWNER_BUYING_OFFER_DOCUMENT,
      variables: { buyingOfferId, sellersAcceptance },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data!.signOwnerBuyingOfferDocument.storageUrl));
  }

  sendBuyingCounterOffer$(buyingOfferId: string, buyersAcceptance: BuyersAcceptanceWithChange): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseSendBuyingCounterOfferDocument,
      { buyingOfferId: string; buyersAcceptance: BuyersAcceptanceWithChange }
    >({
      mutation: SEND_BUYING_COUNTER_OFFER_DOCUMENT,
      variables: { buyingOfferId, buyersAcceptance },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data!.sendBuyingCounterOfferDocument.storageUrl));
  }

  getViewingPassPreviewData$(id: string): Observable<ViewingPassPreviewData> {
    return this._apollo.query<
      GraphQLResponseViewingPassPreviewData,
      { id: string }
    >({
      query: GET_VIEWING_PASS_PREVIEW_DATA,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.viewingPassPreviewData));
  }

  getRentalOfferPreviewData$(id: string): Observable<RentalOfferPreviewData> {
    return this._apollo.query<
      GraphQLResponseRentalOfferPreviewData,
      { id: string }
    >({
      query: GET_RENTAL_OFFER_PREVIEW_DATA,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.rentalOfferPreviewData));
  }

  getBuyingOfferPreviewData$(id: string): Observable<BuyingOfferPreviewData> {
    return this._apollo.query<
      GraphQLResponseBuyingOfferPreviewData,
      { id: string }
    >({
      query: GET_BUYING_OFFER_PREVIEW_DATA,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.buyingOfferPreviewData));
  }

  getPropertyOverviews$(): Observable<PropertyOverview[]> {
    return this._apollo.query<
        GraphQLResponsePropertyOverviews
    >({
      query: GET_PROPERTY_OVERVIEWS,
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.propertyOverviews));
  }

  getPropertyOverviewById$(id: string): Observable<PropertyOverview> {
    return this._apollo.query<
        GraphQLResponsePropertyOverviewById,
        { id: string }
    >({
      query: GET_PROPERTY_OVERVIEW_BY_ID,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(
        map((m) => m.data.propertyOverview),
    );
  }

  getPublicPropertyOverview$(objectId: string): Observable<PropertyOverview> {
    return this._apollo.query<
      GraphQLResponsePublicPropertyOverview,
      { objectId: string }
    >({
      query: GET_PROPERTY_OVERVIEW_BY_OBJECTID,
      variables: { objectId: objectId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.publicPropertyOverview));
  }

  getAgentSettings$(objectId: string): Observable<PropertyOverviewAgent> {
    return this._apollo.query<
      GraphQLResponseAgentSettings,
      { objectId: string }
    >({
      query: GET_AGENT_SETTINGS,
      variables: { objectId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.agentSettings));
  }

  getOverviewFeedback$(accessId: string): Observable<OverviewFeedback> {
    return this._apollo.query<
      GraphQLResponseOverviewFeedback,
      { accessId: string }
    >({
      query: GET_OVERVIEW_FEEDBACK,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.overviewFeedback));
  }

  getPropertyOverviewDocuments$(id: string): Observable<PropertyOverviewDocuments> {
    return this._apollo.query<
      GraphQLResponsePropertyOverviewDocuments,
      { id: string }
    >({
      query: GET_PROPERTY_OVERVIEW_DOCUMENTS,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.propertyOverviewDocuments));
  }

  getViewingPassDocumentPreview$(id: string): Observable<ViewingPassPreviewDocument> {
    return this._apollo.query<
      GraphQLResponseViewingPassDocumentPreview,
      { id: string }
    >({
      query: GET_VIEWING_PASS_DOCUMENT_PREVIEW,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.viewingPassDocumentPreview));
  }

  getRentalOfferDocumentPreview$(id: string): Observable<RentalOfferPreviewDocument> {
    return this._apollo.query<
      GraphQLResponseRentalOfferDocumentPreview,
      { id: string }
    >({
      query: GET_RENTAL_OFFER_DOCUMENT_PREVIEW,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.rentalOfferDocumentPreview));
  }

  getSelfDeclarationDocumentPreview$(id: string): Observable<SelfDeclarationPreviewDocument> {
    return this._apollo.query<
        GraphQLResponseSelfDeclarationDocumentPreview,
        { id: string }
    >({
      query: GET_SELF_DECLARATION_DOCUMENT_PREVIEW,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.selfDeclarationDocumentPreview));
  }

  getBuyingOfferDocumentPreview$(id: string): Observable<BuyingOfferPreviewDocument> {
    return this._apollo.query<
      GraphQLResponseBuyingOfferDocumentPreview,
      { id: string }
    >({
      query: GET_BUYING_OFFER_DOCUMENT_PREVIEW,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.buyingOfferDocumentPreview));
  }

  updatePropertyOverview$(accessId: string, data: PropertyOverviewUpdate): Observable<string> {
    return this._apollo.query<
      GraphQLResponseUpdatePropertyOverview,
      { accessId: string; data: PropertyOverviewUpdate }
    >({
      query: UPDATE_PROPERTY_OVERVIEW,
      variables: { accessId, data },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.updatePropertyOverview));
  }

  updateBuyingOffer$(accessId: string, data: BuyingOfferUpdate): Observable<string> {
    return this._apollo.query<
      GraphQLResponseUpdateBuyingOffer,
      { accessId: string; data: BuyingOfferUpdate }
    >({
      query: UPDATE_BUYING_OFFER,
      variables: { accessId, data },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.updateBuyingOffer));
  }

  updateExclusiveAgreement$(accessId: string, data: ExclusiveAgreementUpdate): Observable<string> {
    return this._apollo.query<
        GraphQLResponseUpdateExclusiveAgreement,
        { accessId: string; data: ExclusiveAgreementUpdate }
    >({
      query: UPDATE_EXCLUSIVE_AGREEMENT,
      variables: { accessId, data },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.updateExclusiveAgreement));
  }

  createOverviewFeedback$(accessId: string, data: CreateOverviewFeedback): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseCreateOverviewFeedback,
      { accessId: string; data: CreateOverviewFeedback }
    >({
      mutation: CREATE_OVERVIEW_FEEDBACK,
      variables: { accessId, data },
    }).pipe(map((m) => m.data!.createOverviewFeedback));
  }

  deleteUploadedDocument$(
    url: string,
    type: PropupDocumentType,
    accessId: string,
  ): Observable<string> {
    return this._apollo.query<
      GraphQLResponseDeleteUploadedDocument,
      { url: string, type: PropupDocumentType, accessId: string }
    >({
      query: DELETE_UPLOADED_DOCUMENT,
      variables: { url, type, accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.deleteUploadedDocument));
  }

  deleteExclusiveAgreementFile$(
    exclusiveAgreementId: string,
    url: string,
  ): Observable<string> {
    return this._apollo.query<
      GraphQLResponseDeleteExclusiveAgreementFile,
      { url: string, exclusiveAgreementId: string }
    >({
      query: DELETE_EXCLUSIVE_AGREEMENT_FILE,
      variables: { url, exclusiveAgreementId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.deleteExclusiveAgreementFile));
  }

  sendViewingPass$(accessId: string): Observable<string> {
    return this._apollo.query<
      GraphQLResponseSendViewingPass,
      { accessId: string }
    >({
      query: SEND_VIEWING_PASS,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.sendViewingPass));
  }

  generateViewingPassDocument$(accessId: string): Observable<string> {
    return this._apollo.query<
      GraphQLResponseGenerateViewingPassDocument,
      { accessId: string }
    >({
      query: GENERATE_VIEWING_PASS_DOCUMENT,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.generateViewingPassDocument.storageUrl));
  }

  sendRentalOffer$(accessId: string): Observable<string> {
    return this._apollo.query<
      GraphQLResponseSendRentalOffer,
      { accessId: string }
    >({
      query: SEND_RENTAL_OFFER,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.sendRentalOffer));
  }

  generateRentalOfferDocument$(accessId: string): Observable<string> {
    return this._apollo.query<
      GraphQLResponseGenerateRentalOfferDocument,
      { accessId: string }
    >({
      query: GENERATE_RENTAL_OFFER_DOCUMENT,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.generateRentalOfferDocument.storageUrl));
  }

  sendSelfDeclaration$(accessId: string): Observable<string> {
    return this._apollo.query<
        GraphQLResponseSendSelfDeclaration,
        { accessId: string }
    >({
      query: SEND_SELF_DECLARATION,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.sendSelfDeclaration));
  }

  generateSelfDeclarationDocument$(accessId: string): Observable<string> {
    return this._apollo.query<
        GraphQLResponseGenerateSelfDeclarationDocument,
        { accessId: string }
    >({
      query: GENERATE_SELF_DECLARATION_DOCUMENT,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.generateSelfDeclarationDocument.storageUrl));
  }

  sendBuyingOffer$(accessId: string): Observable<string> {
    return this._apollo.query<
      GraphQLResponseSendBuyingOffer,
      { accessId: string }
    >({
      query: SEND_BUYING_OFFER,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.sendBuyingOffer));
  }

  generateBuyingOfferDocument$(accessId: string): Observable<string> {
    return this._apollo.query<
      GraphQLResponseGenerateBuyingOfferDocument,
      { accessId: string }
    >({
      query: GENERATE_BUYING_OFFER_DOCUMENT,
      variables: { accessId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.generateBuyingOfferDocument.storageUrl));
  }

  generateExclusiveAgreementDocument$(accessId: string, exclusiveAgreementId: string): Observable<string> {
    return this._apollo.query<
        GraphQLResponseGenerateExclusiveAgreementDocument,
        { accessId: string, exclusiveAgreementId: string}
    >({
      query: GENERATE_EXCLUSIVE_AGREEMENT_DOCUMENT,
      variables: { accessId, exclusiveAgreementId },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.generateExclusiveAgreementDocument.storageUrl));
  }

  createCustomerPortal$(objectId: string, customerId: string, customerEmail: string) {
    return this._apollo.mutate<
      GraphQLResponseCreateCustomerPortal,
      { objectId: string; customerId: string, customerEmail: string }
    >({
      mutation: CREATE_CUSTOMER_PORTAL,
      variables: { objectId, customerId, customerEmail },
      fetchPolicy: 'no-cache',
    }).pipe(map((m: any) => m.data.createCustomerPortal));
  }

  updateCustomerLanguage$(accessId: string, customerLanguage: string) {
    return this._apollo.mutate<
      GraphQLResponseUpdateCustomerLanguage,
      { accessId: string; customerLanguage: string }
    >({
      mutation: UPDATE_CUSTOMER_LANGUAGE,
      variables: { accessId, customerLanguage },
      fetchPolicy: 'no-cache',
    }).pipe(map((m: any) => m.data.updateCustomerLanguage));
  }

  updateOwnerPortalLanguage$(ownerPortalId: string, language: string) {
    return this._apollo.mutate<
      GraphQLResponseUpdateOwnerPortalLanguage,
      { ownerPortalId: string; language: string }
    >({
      mutation: UPDATE_OWNER_PORTAL_LANGUAGE,
      variables: { ownerPortalId, language },
      fetchPolicy: 'no-cache',
    }).pipe(map((m: any) => m.data.updateOwnerPortalLanguage));
  }

  saveOwnerDocuments$(ownerPortalId: string, documents: any[]) {
    return this._apollo.mutate<
      GraphQlResponseSaveOwnerDocuments,
      { ownerPortalId: string; documents: UploadedImage[] }
    >({
      mutation: SAVE_OWNER_DOCUMENTS,
      variables: { ownerPortalId, documents },
      fetchPolicy: 'no-cache',
    }).pipe(map((m: any) => m.data.saveOwnerDocuments));
  }

  verifyCustomerPortalPassword$(accessId: string, password: string) {
    return this._apollo.mutate<
      GraphQlResponseVerifyCustomerPortalPassword,
        { accessId: string; password: string }
    >({
      mutation: VERIFY_CUSTOMER_PORTAL_PASSWORD,
      variables: { accessId, password },
      fetchPolicy: 'no-cache',
    }).pipe(map((m: any) => m.data.verifyCustomerPortalPassword));
  }
}