import { StaticFeatureToggles } from "./static-feature-toggles";

export interface Environment {
  production: boolean;
  environment: EnvironmentType;
  backendBaseUrl: string;
  realFinanzWidgetBaseUrl: string;
  realFinanzWidgetModuleBaseUrl: string;
  realFinanzWidgetStylingUrl: string;
  OBJECT_BACKEND_URL: string;
  googleMapsApiKey?: string;
  staticToggles: StaticFeatureToggles;
  SENTRY_ENVIRONMENT: string;
}

export enum EnvironmentType {
    LOCAL = 'localhost',
    STAGE = 'staging',
    PROD = 'production',
}